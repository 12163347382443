import React from "react"
import styled from "styled-components"

const StyledDiv = styled.div`
  width: 70vmin;
  height: 70vmin;
  @media only screen and (max-width: 992px) and (orientation: portrait) {
    width: calc(100vw - 2rem);
    height: calc(100vw - 2rem);
  }
  margin: 0 auto;
  background-color: #4400cc;

  display: flex;
  align-items: center;
  justify-content: center;
  & > p {
    padding: auto 0;
    text-align: center;
    color: white;
    font-size: 30vmin;
  }
`

interface SymbolProps {
  unicode: string
  name: string
}

const Symbol = ({ unicode, name }: SymbolProps) => {
  return (
    <>
      <StyledDiv className="symbol-container">
        <p>{unicode}</p>
      </StyledDiv>
      <p
        className="symbol-caption sm"
        style={{
          margin: "0 auto",
          textAlign: "center",
          paddingTop: "0.5em",
        }}
      >
        {name}의 기호활자
      </p>
    </>
  )
}

export default Symbol
