import React from "react"

import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Interview from "../components/Interview"
import Image from "../components/image"
import Bio from "../components/bio"
import Symbol from "../components/symbol"

interface CustomProps extends PageProps {
  pageContext: {
    name: string
    title: string
    prev: {
      name: string
      link: string
    }
    next: {
      name: string
      link: string
    }
    en: string
    answers: string
    bio: string
    unicode: string
    caption: string
  }
}

const questions = [
  "해당 문구를 선정한 이유는?",
  "해당 기호 활자를 선정한 이유는? 1번 작품 문구와의 연결성이 있는가?",
  "해당 문구와 레터링(혹은 서체)을 조합했을 때 가장 흥미로웠던 점은?",
  "해당 작업(레터링, 서체 디자인)중 마주한 문제 상황과 그 해결 방법은?",
  "사회가 잠시 멀어진 지금, 작업을 하면서 느낀 가장 큰 달라진 점이 있다면?",
]

const ArtistPage = ({ pageContext, location }: CustomProps) => {
  const {
    name,
    title,
    prev,
    next,
    en,
    answers,
    bio,
    unicode,
    caption,
  } = pageContext

  return (
    <Layout prev={prev} next={next} title={`${name}\n${title}`}>
      <SEO title={name} description="TODO: Add graphql" />
      <Interview
        question={questions[0]}
        answer={
          answers[0] ||
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        }
      />
      <br />
      <Interview
        question={questions[1]}
        answer={
          answers[1] ||
          "Nulla a lobortis est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos."
        }
      />
      <br />
      <Symbol
        name={name.length > 8 ? name.substring(0, 3) : name}
        unicode={unicode}
      />
      <br />
      <Interview
        question={questions[2]}
        answer={
          answers[2] ||
          "Aliquam ultricies arcu diam, nec dapibus quam pharetra in."
        }
      />
      <br />
      <Image name={en} caption={caption} />
      <br />
      <Interview
        question={questions[3]}
        answer={
          answers[3] ||
          "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas."
        }
      />
      <br />
      <Interview
        question={questions[4]}
        answer={
          answers[4] ||
          " Morbi vestibulum lectus in quam dictum, at tempus ante molestie."
        }
      />
      <br />
      <Bio name={name} content={bio} />
    </Layout>
  )
}

export default ArtistPage
