import React from "react"
import styled from "styled-components"

interface BioProps {
  name: string
  content: string
}

const StyledDiv = styled.div`
  width: 50%;
  margin: 0 auto;
  text-align: center;
  color: #4400cc;

  & > p {
    word-break: keep-all;
  }
`

const Bio = ({ name, content }: BioProps) => {
  return (
    <StyledDiv className="bio-container caption sm">
      <p>{name}</p>

      <p>{content}</p>
    </StyledDiv>
  )
}

export default Bio
