import React from "react"

interface Props {
  question: string
  answer: string
}

const padFirstP = (pad: string, content: string) =>
  content.split("\n").map((line, ind) => (
    <p key={ind}>
      {ind === 0 ? pad + "\u3000" : ""}
      {line}
    </p>
  ))
const Interview = ({ question, answer }: Props) => {
  return (
    <div className="interview md" style={{ wordBreak: "keep-all" }}>
      {padFirstP("Q", question)}
      <br />
      {padFirstP("A", answer)}
    </div>
  )
}

export default Interview
