import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import useFrameSize from "../hooks/useFrameSize"
import styled from "styled-components"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Container = styled.div`
  width: 70vmin;
  margin: 0 auto;

  max-height: 70vh;

  @media only screen and (max-width: 992px) and (orientation: portrait) {
    width: calc(100vw - 2rem);
  }
`

const Image = ({ name, caption }) => {
  const [width, height] = useFrameSize()
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "artworks" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(
                maxHeight: 1000
                cropFocus: CENTER
                fit: CONTAIN
                background: "rgba(0, 0, 0, 0)"
                toFormat: PNG
                traceSVG: { color: "#4400CC" }
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  const searchName =
    name === "kka" ? (width < height ? "kka-portrait" : "kka-landscape") : name
  const src = data.allFile.edges
    .map(({ node }) => ({ ...node }))
    .find(node => node.name === searchName)

  return (
    <>
      <Container>
        <Img
          fluid={src.childImageSharp.fluid}
          backgroundColor={"rgba(255,255,255,0)"}
          fadeIn={false}
          loading={"eager"}
          style={{ width: "100%", maxHeight: "inherit", objectFit: "contain" }}
          imgStyle={{
            width: "100%",
            maxHeight: "inherit",
            objectFit: "contain",
          }}
        />
      </Container>
      <p
        className="img-caption caption sm"
        style={{
          color: "#4400CC",
        }}
      >
        {caption}
      </p>
    </>
  )
}

export default Image
